import React from 'react'
import { MDXRenderer as MDXRendererDefault } from 'gatsby-mdx'
import { MDXProvider } from '@mdx-js/react'

const defaultComponents = {}

export const MDXRenderer = ({ children, components = defaultComponents }) => (
    <MDXProvider components={components}>
        <MDXRendererDefault>{children}</MDXRendererDefault>
    </MDXProvider>
)
