import styled from 'styled-components'
import React from 'react'

import Svg from 'assets/icons/file.svg'
import { breakpoints } from '../ui-kit/globalValue'

const Text = styled.li`
    display: flex;
    margin: 20px 0;
    :first-child {
        margin-top: 5px;
    }
    font-size: 20px;
    line-height: 1.2;
    & a {
        text-align: left;
        color: #333;
    }
    @media (min-width: ${breakpoints.tablet}) {
        width: 75%;
        margin: 20px auto;
        text-align: center;
        align-items: center;
    }
`
const FileSvg = styled(Svg)`
    width: 36px;
    height: auto;
    flex-shrink: 0;
    @media (min-width: ${breakpoints.tablet}) {
        width: 36px;
    }
`
export const ReportingLink = (props) => {
    return (
        <Text>
            <FileSvg />
            {props.children}
        </Text>
    )
}
