import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'

import { Page } from 'components/layout/Page'
import { Container } from 'components/ui-kit/Container'
import { Title } from '../components/ui-kit/title'
import { ReportingLink } from 'components/ui-kit/ReportingLink'
import { useTranslations } from 'i18n/useTranslations'
import { AboutHistoryElementDate } from 'components/ui-kit/AboutHistoryElementDate'
import { MDXRenderer } from 'components/ui-kit/MDXRenderer'

const ReportingPage = (props) => {
    const translations = useTranslations()
    const { pageData } = props.data

    return (
        <Page>
            <Helmet>
                <title>
                    {translations.reporting} - {translations.logo} -
                    {translations.siteTitle}
                </title>
                <meta
                    name="description"
                    content="СПСК - Скала-подільський Спецкарʼєр.
                    Звіт незалежного аудитора та фінансова звітність
                    "
                />
            </Helmet>
            <Container>
                <Title as="h1" view="underlined">
                    {translations.reporting}
                </Title>
                <MDXRenderer
                    components={{
                        p: AboutHistoryElementDate,
                        li: ReportingLink,
                    }}
                >
                    {pageData.code.body}
                </MDXRenderer>
            </Container>
        </Page>
    )
}
export default ReportingPage

export const query = graphql`
    query($locale: String! = "uk") {
        pageData: mdx(
            fields: { locale: { eq: $locale }, pageName: { eq: "reportings" } }
        ) {
            frontmatter {
                title
                description
            }
            code {
                body
            }
        }
    }
`
